import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Typography } from "@ryerson/frontend.typography";
import { Accordion, AccordionTab } from "@ryerson/frontend.expansion";
import { Dropdown } from "@ryerson/frontend.form-controls";
import { Media } from "@ryerson/frontend.layout";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import Map from "@components/Locations/Map/Map";
import { useApplication } from "@ryerson/frontend.application";
import { FormatPhoneNumber } from "@components/Locations/Locations";
import { pickPhoneNumberFromString } from "@components/Helper/common";
import { Link } from "@ryerson/frontend.navigation";

const InnerLocationDetails = styled.div`
	box-sizing: border-box;
	display: inline-block;
	width: 45%;
	vertical-align: top;
	padding: 5px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				display: block;
				width: 75%;
				padding: 0px;
			}
		`;
	}}
`;

const InnerLocationDetailsHours = styled.div`
	box-sizing: border-box;
	display: inline-block;
	width: 55%;
	vertical-align: top;
	padding: 5px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				display: block;
				width: 75%;
				padding: 0px;
				padding-bottom: 15px;
			}
		`;
	}}
`;

const HoursDaysSection = styled.div`
	display: inline-block;
	width: 24%;
`;

const HoursHoursSection = styled.div`
	display: inline-block;
	width: 75%;
`;

const MapSection = styled.div`
	display: block;
	width: 100%;
	height: 185px;
	margin-top: 25px;
`;

const Divider = styled.hr`
	display: block;
	width: 100%;
	opacity: 0.1;
	margin-top: 0px;
	margin-bottom: 0px;
`;

const Spacer = styled.div`
	display: block;
	width: 100%;
	height: 20px;
`;

type ContactUsPlantStaticContent = {
	selectState: string;
	phone: string;
	hours: string;
	call: string;
	afterHours: string;
    locationDetails: string;
};

const StaticContent: LanguageContent<ContactUsPlantStaticContent> = {
	en: {
		selectState: "Select State",
		phone: "Phone",
		hours: "Office Hours",
		call: "Call",
		afterHours: "for after-hours service",
        locationDetails: "View Location Details"
	},
	fr: {
		selectState: "Sélectionnez l'état",
		phone: "Téléphoner",
		hours: "Heures de travail",
		call: "Appelez le",
		afterHours: "pour le service après les heures",
        locationDetails: "Afficher les détails sur l’emplacement"
	},
	es: {
		selectState: "Seleccione estado",
		phone: "Teléfono",
		hours: "Horas de oficina",
		call: "Llame al",
		afterHours: "para obtener servicio fuera del horario de atención",
        locationDetails: "Ver detalles de la ubicación"
	},
};

export type ContactUsPlantType = {
	defaultState: any;
	stateOptions: any;
	languageContent: any;
};

export const ContactUsPlant: React.FC<ContactUsPlantType> = ({
	defaultState,
	stateOptions,
	languageContent,
}) => {
	const { theme } = useTheme();

	const context = useApplication();
	const {
		localization: { language },
	} = context;

	const [currentState, setCurrentState] = React.useState<string>(defaultState);
	React.useEffect(() => {
		setCurrentState(defaultState);
	}, [defaultState]);
	const containerStyles = css`
		padding: 10px;
		@media only screen and (max-width: ${theme.breakpoints.lg}) {
			padding: 0px;
		}
	`;

	return (
		<div css={containerStyles}>
			<Dropdown
				language={language}
				id={"subjectDropdown"}
				value={currentState}
				onChange={(value) => {
					setCurrentState(value);
				}}
				options={stateOptions}
				label={StaticContent[language].selectState}
			/>
			<Spacer />
			{languageContent &&
				languageContent.sort((a:any, b:any) => { 
                    if (a.facilityType === "Ryerson Facility" && b.facilityType === "Ryerson Family of Companies Facility" ){
                        return -1;
                    }else if (a.facilityType === "Ryerson Family of Companies Facility" && b.facilityType === "Ryerson Facility"){
                        return 1;
                    }else{
                        return 0;
                    }
                }).map((location: any, index: number) => {
                        let link = "/locations/" + String(location.country).toLowerCase().replace(" ", "-") + "/";
                        if (location.country && location.country !== "Mexico") {
                            link += String(location.state).toLowerCase().replace(" ", "-") + "/" + location.slug;
                        } else {
                            link += location.slug;
                        }
					return (
						location.state === currentState && (
							<React.Fragment key={index}>
								<Divider
									css={css`
										color: ${theme.colors.primary.darkerGray};
									`}
								/>
								<Accordion multiple={true} defaultOpen={0}>
									<AccordionTab
										dataTabId={location.locationName}
										header={location.locationName}
										circled={true}
									>
										<Typography
											variant="p"
											size="md"
											color={theme.colors.primary.secondaryBrand}
										>
											{location.streetAddress +
												" " +
												location.city +
												" " +
												location.state +
												" " +
												location.zipCode}
										</Typography>
                                        <Link to={link}>
                                            {StaticContent[language].locationDetails}
                                        </Link>
										<Divider
											css={css`
												margin-top: 20px;
												margin-bottom: 20px;
												color: ${theme.colors.primary.darkerGray};
											`}
										/>
										<InnerLocationDetails theme={theme}>
											<Media lessThan="lg">
												<Typography
													variant="p"
													color={theme.colors.primary.secondaryBrand}
													weight="bold"
													size="md"
												>
													{StaticContent[language].phone}
												</Typography>
												<Typography
													variant="p"
													color={theme.colors.primary.secondaryBrand}
													weight="bold"
													size="md"
												>
													{FormatPhoneNumber(location.phoneNumber)}
												</Typography>
												<Typography variant="p" size="md">
													{StaticContent[language].call}
													<Typography
														font="inherit"
														color={theme.colors.primary.secondaryBrand}
														weight="bold"
														size="xl"
													>
														{" "}
														{
															pickPhoneNumberFromString(
																location.state
															).number
														}
													</Typography>{" "}
													<br></br>
													{StaticContent[language].afterHours}
												</Typography>
											</Media>
											<Media greaterThanOrEqual="lg">
												<Typography
													variant="p"
													color={theme.colors.primary.secondaryBrand}
													weight="bold"
													size="sm"
												>
													{StaticContent[language].phone}
												</Typography>
												<Typography
													variant="p"
													color={theme.colors.primary.secondaryBrand}
													size="sm"
												>
													{FormatPhoneNumber(location.phoneNumber)}
												</Typography>
												<Typography variant="p" size="sm">
													{StaticContent[language].call}
													<Typography
														font="inherit"
														color={theme.colors.primary.secondaryBrand}
														weight="bold"
													>
														{" "}
														{
															pickPhoneNumberFromString(
																location.state
															).number
														}
													</Typography>{" "}
													{StaticContent[language].afterHours}
												</Typography>
											</Media>
										</InnerLocationDetails>
										<InnerLocationDetailsHours theme={theme}>
											<Media lessThan="lg">
												<Typography
													variant="p"
													color={theme.colors.primary.secondaryBrand}
													weight="bold"
													size="md"
												>
													{StaticContent[language].hours}
												</Typography>
												<HoursDaysSection>
													<Typography variant="div" size="md">
														Mon:
													</Typography>
													<Typography variant="div" size="md">
														Tue:
													</Typography>
													<Typography variant="div" size="md">
														Wed:
													</Typography>
													<Typography variant="div" size="md">
														Thu:
													</Typography>
													<Typography variant="div" size="md">
														Fri:
													</Typography>
												</HoursDaysSection>
												<HoursHoursSection>
													<Typography
														variant="div"
														color={theme.colors.primary.secondaryBrand}
														weight="bold"
														size="md"
													>
														{location.locationHours &&
														location.locationHours.mondayOpen &&
														location.locationHours.mondayClose
															? location.locationHours.mondayOpen +
															  " - " +
															  location.locationHours.mondayClose
															: "--"}
													</Typography>
													<Typography
														variant="div"
														color={theme.colors.primary.secondaryBrand}
														weight="bold"
														size="md"
													>
														{location.locationHours &&
														location.locationHours.tuesdayOpen &&
														location.locationHours.tuesdayClose
															? location.locationHours.tuesdayOpen +
															  " - " +
															  location.locationHours.tuesdayClose
															: "--"}
													</Typography>
													<Typography
														variant="div"
														color={theme.colors.primary.secondaryBrand}
														weight="bold"
														size="md"
													>
														{location.locationHours &&
														location.locationHours.wednesdayOpen &&
														location.locationHours.wednesdayClose
															? location.locationHours.wednesdayOpen +
															  " - " +
															  location.locationHours.wednesdayClose
															: "--"}
													</Typography>
													<Typography
														variant="div"
														color={theme.colors.primary.secondaryBrand}
														weight="bold"
														size="md"
													>
														{location.locationHours &&
														location.locationHours.thursdayOpen &&
														location.locationHours.thursdayClose
															? location.locationHours.thursdayOpen +
															  " - " +
															  location.locationHours.thursdayClose
															: "--"}
													</Typography>
													<Typography
														variant="div"
														color={theme.colors.primary.secondaryBrand}
														weight="bold"
														size="md"
													>
														{location.locationHours &&
														location.locationHours.fridayOpen &&
														location.locationHours.fridayClose
															? location.locationHours.fridayOpen +
															  " - " +
															  location.locationHours.fridayClose
															: "--"}
													</Typography>
												</HoursHoursSection>
											</Media>
											<Media greaterThanOrEqual="lg">
												<Typography
													variant="p"
													color={theme.colors.primary.secondaryBrand}
													weight="bold"
													size="sm"
												>
													{StaticContent[language].hours}
												</Typography>
												<HoursDaysSection>
													<Typography variant="div" size="sm">
														Mon:
													</Typography>
													<Typography variant="div" size="sm">
														Tue:
													</Typography>
													<Typography variant="div" size="sm">
														Wed:
													</Typography>
													<Typography variant="div" size="sm">
														Thu:
													</Typography>
													<Typography variant="div" size="sm">
														Fri:
													</Typography>
												</HoursDaysSection>
												<HoursHoursSection>
													<Typography
														variant="div"
														color={theme.colors.primary.secondaryBrand}
														weight="bold"
														size="md"
													>
														{location.locationHours &&
														location.locationHours.mondayOpen &&
														location.locationHours.mondayClose
															? location.locationHours.mondayOpen +
															  " - " +
															  location.locationHours.mondayClose
															: "--"}
													</Typography>
													<Typography
														variant="div"
														color={theme.colors.primary.secondaryBrand}
														weight="bold"
														size="md"
													>
														{location.locationHours &&
														location.locationHours.tuesdayOpen &&
														location.locationHours.tuesdayClose
															? location.locationHours.tuesdayOpen +
															  " - " +
															  location.locationHours.tuesdayClose
															: "--"}
													</Typography>
													<Typography
														variant="div"
														color={theme.colors.primary.secondaryBrand}
														weight="bold"
														size="md"
													>
														{location.locationHours &&
														location.locationHours.wednesdayOpen &&
														location.locationHours.wednesdayClose
															? location.locationHours.wednesdayOpen +
															  " - " +
															  location.locationHours.wednesdayClose
															: "--"}
													</Typography>
													<Typography
														variant="div"
														color={theme.colors.primary.secondaryBrand}
														weight="bold"
														size="md"
													>
														{location.locationHours &&
														location.locationHours.thursdayOpen &&
														location.locationHours.thursdayClose
															? location.locationHours.thursdayOpen +
															  " - " +
															  location.locationHours.thursdayClose
															: "--"}
													</Typography>
													<Typography
														variant="div"
														color={theme.colors.primary.secondaryBrand}
														weight="bold"
														size="md"
													>
														{location.locationHours &&
														location.locationHours.fridayOpen &&
														location.locationHours.fridayClose
															? location.locationHours.fridayOpen +
															  " - " +
															  location.locationHours.fridayClose
															: "--"}
													</Typography>
												</HoursHoursSection>
											</Media>
										</InnerLocationDetailsHours>
										<MapSection>
											<Map
												googleKey={process.env.GATSBY_GOOGLE_MAPS_KEY ?? ""}
												mapData={[
													{
														location: {
															lat: location.location.lat,
															lng: location.location.lon,
														},
														name: location.streetAddress,
														id: location.streetAddress,
													},
												]}
												zoomLevel={11}
											></Map>
										</MapSection>
										<Spacer />
									</AccordionTab>
								</Accordion>
							</React.Fragment>
						)
					);
				})}
		</div>
	);
};
